// 
// tables.scss
//

th {
    font-weight: $table-head-font-weight;
}

//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Table 

.table-nowrap {
    th,td {
        white-space: nowrap;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: #{map-get($grays, "600")};
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: #{map-get($grays, "700")};
    }
}



table {
    // Table Hover
    &.table-hover {
        thead,
        tbody {
            tr {
                &:hover {
                    > * {
                        --ct-table-accent-bg: #f1f3fa;
                    }
                }
            }
        }
    }       
    // Table Active 
    tr {
        &.table-active {
            --ct-table-accent-bg: rgba(222, 226, 230, 0.3);
        }
    }
    //  Table Striped
    &.table-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    >* {
                        --ct-table-accent-bg: rgba(222, 226, 230, 0.3);
                    }
                }
            }
        }
    }
}


// dark mode
body[data-layout-color="dark"] {
    table {
        .action-icon {
            color: #{map-get($dark-grays, "600")};
    
            &:hover {
                color: #{map-get($dark-grays, "700")};
            }
        }
        // Table Light - Dark
        .table-light {
            --ct-table-bg: #3a4250;
            color: $white;
            border-color: $table-group-separator-color;
        }
        .table-dark {
            --ct-table-bg: #3a4250;
        }
        // Table Hover
        &.table-hover {
            thead,
            tbody {
                tr {
                    &:hover {
                        > * {
                            --ct-table-accent-bg: rgba(64, 73, 84, 0.8);
                        }
                    }
                }
            }
        }       
        // Table Active 
        tr {
            &.table-active {
                --ct-table-accent-bg: rgba(64, 73, 84, 0.8);
            }
        }
        //  Table Striped
        &.table-striped {
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        >* {
                            --ct-table-accent-bg: rgba(64, 73, 84, 0.8);
                        }
                    }
                }
            }
        }
    }
}

